import React from "react";

const Footer = () => {
  return (
    <div>
      <p>{new Date().getYear()} Copyyright. All rights reserverd.</p>
    </div>
  );
};

export default Footer;
